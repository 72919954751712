.circle {
  border-radius: 50% !important;
  padding: 5px !important;
}
.slim {
  padding: 0 !important;
}
.base {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
}
.base:disabled,
.base.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .base:disabled,
  .base.disabled {
    filter: initial;
  }
}
.base.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .base.disabled svg > * {
    filter: initial;
  }
}
.buttonTransparent {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: transparent;
  color: inherit;
}
.buttonTransparent:disabled,
.buttonTransparent.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonTransparent:disabled,
  .buttonTransparent.disabled {
    filter: initial;
  }
}
.buttonTransparent.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonTransparent.disabled svg > * {
    filter: initial;
  }
}
.buttonTransparent:active,
.buttonTransparent.active {
  background-image: var(--active-overlay);
}
.buttonTransparent:hover {
  background-image: var(--hover-overlay);
}
.buttonTransparent:focus {
  background-image: var(--focus-overlay);
}
.buttonPrimary {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--primary-background);
  border-color: var(--primary-border);
  color: var(--primary-text);
  --icon-color: var(--primary-text);
  --border: var(--primary-border);
  --hover-overlay: var(--primary-hover-overlay);
  --focus-overlay: var(--primary-focus-overlay);
  --active-overlay: var(--primary-active-overlay);
}
.buttonPrimary:disabled,
.buttonPrimary.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonPrimary:disabled,
  .buttonPrimary.disabled {
    filter: initial;
  }
}
.buttonPrimary.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonPrimary.disabled svg > * {
    filter: initial;
  }
}
.buttonPrimary a {
  color: var(--primary-link);
}
.buttonPrimary:active,
.buttonPrimary.active {
  background-image: var(--active-overlay);
}
.buttonPrimary:hover {
  background-image: var(--hover-overlay);
}
.buttonPrimary:focus {
  background-image: var(--focus-overlay);
}
.buttonQuestion {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--question-background);
  border-color: var(--question-border);
  color: var(--question-text);
  --icon-color: var(--question-text);
  --border: var(--question-border);
  --hover-overlay: var(--question-hover-overlay);
  --focus-overlay: var(--question-focus-overlay);
  --active-overlay: var(--question-active-overlay);
}
.buttonQuestion:disabled,
.buttonQuestion.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonQuestion:disabled,
  .buttonQuestion.disabled {
    filter: initial;
  }
}
.buttonQuestion.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonQuestion.disabled svg > * {
    filter: initial;
  }
}
.buttonQuestion a {
  color: var(--question-link);
}
.buttonQuestion:active,
.buttonQuestion.active {
  background-image: var(--active-overlay);
}
.buttonQuestion:hover {
  background-image: var(--hover-overlay);
}
.buttonQuestion:focus {
  background-image: var(--focus-overlay);
}
.buttonInfo {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--info-background);
  border-color: var(--info-border);
  color: var(--info-text);
  --icon-color: var(--info-text);
  --border: var(--info-border);
  --hover-overlay: var(--info-hover-overlay);
  --focus-overlay: var(--info-focus-overlay);
  --active-overlay: var(--info-active-overlay);
}
.buttonInfo:disabled,
.buttonInfo.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonInfo:disabled,
  .buttonInfo.disabled {
    filter: initial;
  }
}
.buttonInfo.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonInfo.disabled svg > * {
    filter: initial;
  }
}
.buttonInfo a {
  color: var(--info-link);
}
.buttonInfo:active,
.buttonInfo.active {
  background-image: var(--active-overlay);
}
.buttonInfo:hover {
  background-image: var(--hover-overlay);
}
.buttonInfo:focus {
  background-image: var(--focus-overlay);
}
.buttonSecondary {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--secondary-background);
  border-color: var(--secondary-border);
  color: var(--secondary-text);
  --icon-color: var(--secondary-text);
  --border: var(--secondary-border);
  --hover-overlay: var(--secondary-hover-overlay);
  --focus-overlay: var(--secondary-focus-overlay);
  --active-overlay: var(--secondary-active-overlay);
}
.buttonSecondary:disabled,
.buttonSecondary.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonSecondary:disabled,
  .buttonSecondary.disabled {
    filter: initial;
  }
}
.buttonSecondary.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonSecondary.disabled svg > * {
    filter: initial;
  }
}
.buttonSecondary a {
  color: var(--secondary-link);
}
.buttonSecondary:active,
.buttonSecondary.active {
  background-image: var(--active-overlay);
}
.buttonSecondary:hover {
  background-image: var(--hover-overlay);
}
.buttonSecondary:focus {
  background-image: var(--focus-overlay);
}
.buttonTertiary {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--tertiary-background);
  border-color: var(--tertiary-border);
  color: var(--tertiary-text);
  --icon-color: var(--tertiary-text);
  --border: var(--tertiary-border);
  --hover-overlay: var(--tertiary-hover-overlay);
  --focus-overlay: var(--tertiary-focus-overlay);
  --active-overlay: var(--tertiary-active-overlay);
}
.buttonTertiary:disabled,
.buttonTertiary.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonTertiary:disabled,
  .buttonTertiary.disabled {
    filter: initial;
  }
}
.buttonTertiary.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonTertiary.disabled svg > * {
    filter: initial;
  }
}
.buttonTertiary a {
  color: var(--tertiary-link);
}
.buttonTertiary:active,
.buttonTertiary.active {
  background-image: var(--active-overlay);
}
.buttonTertiary:hover {
  background-image: var(--hover-overlay);
}
.buttonTertiary:focus {
  background-image: var(--focus-overlay);
}
.buttonSidebar {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--sidebar-background);
  border-color: var(--sidebar-border);
  color: var(--sidebar-text);
  --icon-color: var(--sidebar-text);
  --border: var(--sidebar-border);
  --hover-overlay: var(--sidebar-hover-overlay);
  --focus-overlay: var(--sidebar-focus-overlay);
  --active-overlay: var(--sidebar-active-overlay);
}
.buttonSidebar:disabled,
.buttonSidebar.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonSidebar:disabled,
  .buttonSidebar.disabled {
    filter: initial;
  }
}
.buttonSidebar.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonSidebar.disabled svg > * {
    filter: initial;
  }
}
.buttonSidebar a {
  color: var(--sidebar-link);
}
.buttonSidebar:active,
.buttonSidebar.active {
  background-image: var(--active-overlay);
}
.buttonSidebar:hover {
  background-image: var(--hover-overlay);
}
.buttonSidebar:focus {
  background-image: var(--focus-overlay);
}
.buttonDefault {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--default-background);
  border-color: var(--default-border);
  color: var(--default-text);
  --icon-color: var(--default-text);
  --border: var(--default-border);
  --hover-overlay: var(--default-hover-overlay);
  --focus-overlay: var(--default-focus-overlay);
  --active-overlay: var(--default-active-overlay);
}
.buttonDefault:disabled,
.buttonDefault.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonDefault:disabled,
  .buttonDefault.disabled {
    filter: initial;
  }
}
.buttonDefault.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonDefault.disabled svg > * {
    filter: initial;
  }
}
.buttonDefault a {
  color: var(--default-link);
}
.buttonDefault:active,
.buttonDefault.active {
  background-image: var(--active-overlay);
}
.buttonDefault:hover {
  background-image: var(--hover-overlay);
}
.buttonDefault:focus {
  background-image: var(--focus-overlay);
}
.buttonWarning {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--warning-background);
  border-color: var(--warning-border);
  color: var(--warning-text);
  --icon-color: var(--warning-text);
  --border: var(--warning-border);
  --hover-overlay: var(--warning-hover-overlay);
  --focus-overlay: var(--warning-focus-overlay);
  --active-overlay: var(--warning-active-overlay);
}
.buttonWarning:disabled,
.buttonWarning.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonWarning:disabled,
  .buttonWarning.disabled {
    filter: initial;
  }
}
.buttonWarning.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonWarning.disabled svg > * {
    filter: initial;
  }
}
.buttonWarning a {
  color: var(--warning-link);
}
.buttonWarning:active,
.buttonWarning.active {
  background-image: var(--active-overlay);
}
.buttonWarning:hover {
  background-image: var(--hover-overlay);
}
.buttonWarning:focus {
  background-image: var(--focus-overlay);
}
.buttonError {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--error-background);
  border-color: var(--error-border);
  color: var(--error-text);
  --icon-color: var(--error-text);
  --border: var(--error-border);
  --hover-overlay: var(--error-hover-overlay);
  --focus-overlay: var(--error-focus-overlay);
  --active-overlay: var(--error-active-overlay);
}
.buttonError:disabled,
.buttonError.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonError:disabled,
  .buttonError.disabled {
    filter: initial;
  }
}
.buttonError.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonError.disabled svg > * {
    filter: initial;
  }
}
.buttonError a {
  color: var(--error-link);
}
.buttonError:active,
.buttonError.active {
  background-image: var(--active-overlay);
}
.buttonError:hover {
  background-image: var(--hover-overlay);
}
.buttonError:focus {
  background-image: var(--focus-overlay);
}
.buttonSuccess {
  border-radius: var(--border-radius);
  border: none;
  min-height: 44px;
  min-width: 44px;
  background-size: 16px;
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  outline: 0 none;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  background-color: var(--success-background);
  border-color: var(--success-border);
  color: var(--success-text);
  --icon-color: var(--success-text);
  --border: var(--success-border);
  --hover-overlay: var(--success-hover-overlay);
  --focus-overlay: var(--success-focus-overlay);
  --active-overlay: var(--success-active-overlay);
}
.buttonSuccess:disabled,
.buttonSuccess.disabled {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonSuccess:disabled,
  .buttonSuccess.disabled {
    filter: initial;
  }
}
.buttonSuccess.disabled svg > * {
  filter: grayscale(1);
  color: var(--disabled-text);
  background-image: linear-gradient(var(--disabled-overlay), var(--disabled-overlay));
  background-size: unset;
  background-repeat: unset;
  opacity: unset;
  cursor: not-allowed;
}
@media print {
  .buttonSuccess.disabled svg > * {
    filter: initial;
  }
}
.buttonSuccess a {
  color: var(--success-link);
}
.buttonSuccess:active,
.buttonSuccess.active {
  background-image: var(--active-overlay);
}
.buttonSuccess:hover {
  background-image: var(--hover-overlay);
}
.buttonSuccess:focus {
  background-image: var(--focus-overlay);
}
.iconSmall {
  width: 8px;
  height: 8px;
}
.iconLarge {
  width: 24px;
  height: 24px;
}
.iconExtraLarge {
  width: 32px;
  height: 32px;
}
.wrapper {
  display: grid;
  gap: 6px;
  grid-auto-flow: column;
  align-items: center;
}
.desktop {
  min-height: 28px;
  min-width: 20px;
}
.desktop.circle {
  min-width: 28px;
  padding: 0;
}
.buttonWithText {
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
  gap: 4px;
  grid-template-columns: max-content 1fr;
  align-items: center;
}
