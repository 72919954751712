.container {
  display: flex;
  justify-self: center;
  align-self: center;
  height: inherit;
  user-select: none;
}
.container > svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
}
.container > svg *[fill="none"] {
  display: none;
}
.container:not(:global(.keep)) > svg path:not(:global(.keep)),
.container:not(:global(.keep)) > svg polygon:not(:global(.keep)),
.container:not(:global(.keep)) > svg circle:not(:global(.keep)),
.container:not(:global(.keep)) > svg text:not(:global(.keep)),
.container:not(:global(.keep)) > svg ellipse:not(:global(.keep)) {
  fill: var(--icon-color);
  position: relative;
}
.container_scale_11 {
  display: flex;
  justify-self: center;
  align-self: center;
  height: inherit;
  user-select: none;
}
.container_scale_11 > svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
}
.container_scale_11 > svg *[fill="none"] {
  display: none;
}
.container_scale_11:not(:global(.keep)) > svg path:not(:global(.keep)),
.container_scale_11:not(:global(.keep)) > svg polygon:not(:global(.keep)),
.container_scale_11:not(:global(.keep)) > svg circle:not(:global(.keep)),
.container_scale_11:not(:global(.keep)) > svg text:not(:global(.keep)),
.container_scale_11:not(:global(.keep)) > svg ellipse:not(:global(.keep)) {
  fill: var(--icon-color);
  position: relative;
}
.container_scale_11 > svg > * {
  transform: scale(1.1);
  transform-origin: center;
}
.container_scale_15 {
  display: flex;
  justify-self: center;
  align-self: center;
  height: inherit;
  user-select: none;
}
.container_scale_15 > svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
}
.container_scale_15 > svg *[fill="none"] {
  display: none;
}
.container_scale_15:not(:global(.keep)) > svg path:not(:global(.keep)),
.container_scale_15:not(:global(.keep)) > svg polygon:not(:global(.keep)),
.container_scale_15:not(:global(.keep)) > svg circle:not(:global(.keep)),
.container_scale_15:not(:global(.keep)) > svg text:not(:global(.keep)),
.container_scale_15:not(:global(.keep)) > svg ellipse:not(:global(.keep)) {
  fill: var(--icon-color);
  position: relative;
}
.container_scale_15 > svg > * {
  transform: scale(1.5);
  transform-origin: center;
}
.container_scale_1_75 {
  display: flex;
  justify-self: center;
  align-self: center;
  height: inherit;
  user-select: none;
}
.container_scale_1_75 > svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
}
.container_scale_1_75 > svg *[fill="none"] {
  display: none;
}
.container_scale_1_75:not(:global(.keep)) > svg path:not(:global(.keep)),
.container_scale_1_75:not(:global(.keep)) > svg polygon:not(:global(.keep)),
.container_scale_1_75:not(:global(.keep)) > svg circle:not(:global(.keep)),
.container_scale_1_75:not(:global(.keep)) > svg text:not(:global(.keep)),
.container_scale_1_75:not(:global(.keep)) > svg ellipse:not(:global(.keep)) {
  fill: var(--icon-color);
  position: relative;
}
.container_scale_1_75 > svg > * {
  transform: scale(1.75);
  transform-origin: center;
}
.materialSymbols {
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}
